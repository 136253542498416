/* App.js */
/* Written by darroll saddi at uc davis */
/* Utilizes UC Davis API by Marvin */

body {
  background-color: rgb(20, 20, 20);
  font-family: 'Trebuchet Ms';
  font-weight: bold;
  color: white;
}

.subtitle {
  margin-top: -17px;
  font-style: italic;
  font-weight: normal;
  text-align: center;
}

/* style the blue link */
.blue-link {
  color: rgb(0, 110, 255); 
  text-decoration: underline; 
  cursor: pointer; /* change the cursor to a pointer on hover */
}

.red-text {
  color: red;
}

/* center the chart and slider on the screen */
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  max-width:100%
}

.bar-chart {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 93%;
  max-width: 300px; /* limit the width to prevent stretching too much */
  height: 250px;
  margin: 17px auto;
  padding: 10px; /* add padding for spacing from the edges */
}

.bar {
  width: 75px; /* adjust the width of the bars */
  transition: height 0.5s ease; /* add a smooth height transition effect */
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}

/* style individual bars */
.seats-available {
  background-color: rgb(0, 113, 0);
  color: white;
}

.seats-reserved {
  background-color: hsl(46, 100%, 44%);
  color: white;
}

.waitlist {
  background-color: rgb(212, 0, 0);
  color: white;
}

/* style the slider */
input[type="range"] {
  width: 100%;
  margin-top: 20px;
}

/* style the UpdatedAt text */
.updatedAt {
  text-align: center;
}

/* style the legend */
.legend {
  display: flex;
  align-items: center;
  margin: 0 auto;
  max-width:100%;
}

/* style the legend text */
.legend-item {
  display: flex;
  align-items: center;
  margin-right: 10px;
  font-style: italic;
  font-weight: normal;
}

/* style the legend boxes */
.legend-box {
  width: 20px;
  height: 15px;
  margin-right: 5px;
}

/* Limit the width of the slider */
.slider-container {
  max-width: 90%;
  margin: 0 auto;
}

/* Position checkbox */
.checkbox-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top: -17.5px;
  font-style: italic;
  font-weight: normal;
  max-width:90%;
}